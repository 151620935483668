/** @format */

import ApiConfig from "utils/API";

const handleContact = async (name, email, subject, message) => {
    return await ApiConfig.post(`/contact`, {
        name,
        email,
        subject,
        message,
    });
};

const ContactService = { handleContact };

export default ContactService;
